<template>
  <v-app>
    <div class="fill-height">
      <v-container>
        <v-row class="flex-column mt-10 mb-4">
          <v-col>
            <h3
              class="font-semibold text-display-5 text-black text-3xl text-[#6867AC] text-capitalize"
            >
              Kedas Beauty
            </h3>
            <h4 class="font-normal text-[#6867AC]">Member List</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" lg="4" xl="4">
            <div
              class="bg-gradient-to-tr from-[#B597F6] to-[#96C6EA] text-white rounded-3xl shadow-lg sticky top-5"
            >
              <v-form ref="filterForm" class="p-8">
                <v-autocomplete
                  outlined
                  dark
                  rounded
                  :items="select.countries"
                  item-text="name"
                  item-value="iso2"
                  label="Negara/Country"
                  v-model="selected.country"
                  @change="getStates"
                  :disabled="loading"
                />

                <v-autocomplete
                  outlined
                  dark
                  rounded
                  :items="select.states"
                  item-text="name"
                  item-value="iso2"
                  label="Provinsi/State"
                  v-model="selected.state"
                  @change="getCities"
                  :disabled="select.states.length === 0"
                />

                <v-autocomplete
                  outlined
                  dark
                  rounded
                  :items="select.cities"
                  item-text="name"
                  item-value="name"
                  label="Kota/City"
                  v-model="selected.district"
                  :disabled="selected.state === null ? true : false"
                />
                <v-text-field
                  dark
                  outlined
                  rounded
                  clearable
                  :disabled="loading"
                  v-model="selected.name"
                  label="Cari Nama"
                />
                <v-row class="rounded-full text-center">
                  <div class="w-1/2 text-center px-2">
                    <v-btn
                      block
                      large
                      color="white"
                      @click="search"
                      class="rounded-pill"
                    >
                      <span class="indigo--text font-weight-bold">Cari</span>
                      <v-icon color="indigo" right>mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                  <div class="w-1/2 text-center px-2">
                    <v-btn
                      large
                      block
                      @click="resetSearch"
                      class="rounded-pill"
                    >
                      <span class="indigo--text font-weight-bold">Reset</span>
                      <v-icon color="indigo" right>mdi-reload</v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-form>
            </div> </v-col
          ><v-col cols="12" md="8" class="p-9 shadow-lg rounded-xl">
            <h3
              v-if="(member.data = member.data)"
              class="font-semibold text-xl text-[#6867AC] md:px-12"
            >
              Hasil Pencarian: {{ member.total }} Data
            </h3>
            <br />
            <!-- loading -->
            <v-alert
              border="top"
              colored-border
              elevation="2"
              color="light-blue accent-4"
              v-if="active"
            >
              <br />
              <p class="text-center">Loading, Please Wait...</p>
              <br />
              <v-progress-linear
                indeterminate
                color="light-blue accent-4"
                :active="active"
              >
              </v-progress-linear>
            </v-alert>

            <!-- reseller list -->
            <div
              class="my-2 p-4 md:px-12"
              v-for="(mem, index) in member.data"
              :key="index"
            >
              <div
                class="flex flex-row cursor-pointer hover:drop-shadow-lg transition ease-in-out"
                @click="goToResellerLink(mem.website_id)"
              >
                <v-avatar
                  size="48"
                  class="mr-5 rounded-full my-4"
                  :color="index % 2 === 0 ? 'bg-[#398AB9]' : 'bg-[#A267AC]'"
                >
                  <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
                <div
                  width="100%"
                  class="rounded-3xl relative flex flex-col text-white p-5 md:flex-row"
                  :class="index % 2 === 0 ? 'bg-[#398AB9]' : 'bg-[#A267AC]'"
                >
                  <div
                    class="rounded-full border-4 absolute border-white top-[-15px] right-[-15px] h-12 w-12 flex justify-center"
                    :class="index % 2 === 0 ? 'bg-[#398AB9]' : 'bg-[#A267AC]'"
                  >
                    <v-icon color="white">mdi-cart-variant</v-icon>
                  </div>
                  <div class="flex flex-col">
                    <p class="font-semibold text-capitalize text-lg">
                      {{ mem.name }}
                      <v-icon v-if="mem.is_active === 'yes'" color="white" right
                        >mdi-check-decagram</v-icon
                      >
                    </p>
                    <p class="font-normal text-capitalize">{{ mem.address }}</p>
                  </div>

                  <p class="font-medium text-capitalize self-start md:self-end">
                    {{ mem.district }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Not Found Message -->
            <v-row>
              <v-col class="d-flex justify-center grow">
                <v-slide-x-transition>
                  <div v-if="found">
                    <v-alert
                      border="top"
                      colored-border
                      color="warning"
                      width="100%"
                      icon="mdi-information-outline"
                      elevation="2"
                      max-height="500"
                    >
                      <h3 class="font-weight-light">
                        Reseller tidak ditemukan
                      </h3>
                    </v-alert>
                  </div>
                </v-slide-x-transition>
              </v-col>
            </v-row>
            <v-pagination
              color="indigo"
              v-model="member.current_page"
              :length="member.last_page"
              total-visible="5"
              @input="handlePagination"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
      <br />
    </div>
    <footer class="bg-blue-200 text-[#6867AC] pb-24 pt-16 text-center px-16">
      <div class="flex flex-col md:flex-row justify-center w-full">
        <div class="w-auto text-left p-8">
          <p class="font-bold text-xl">Kedas Beauty</p>
          <p>
            Copyright &copy; {{ new Date().getFullYear() }} Kedas Beauty owned
            by PT. Mahapurna Mandiri Utama.
          </p>
          <p>All Rights Reserved.</p>
        </div>
        <div class="w-auto text-left p-8">
          <p class="font-bold text-xl">Info</p>
          <!-- Vue Link -->
          <a
            
            href="https://wa.me/6282353333621"
            target="_blank"
          >
            <span class="text-[#6867AC] hover:text-indigo-400 transition ease-in-out cursor-pointer">
              Join Reseller Kami
            </span>
          </a>
          <br />
          <a
            href="https://wa.me/6282353333621"
            target="_blank"
          >
            <span class="text-[#6867AC] hover:text-indigo-400 transition ease-in-out cursor-pointer">
              Hubungi Kami
            </span>
          </a>
          <p class="font-bold text-xl mt-4">Follow Kami</p>
          <div class="flex flex-row space-x-4">
            <a
              href="https://www.youtube.com/c/CandraDewiFikaOfficial"
              target="_blank"
              rel="noopener noreferrer"
              class="cursor-pointer"
            >
              <i
                class="fa-brands fa-youtube fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
              ></i>
            </a>
            <a
              href="https://www.instagram.com/kedas_beautyofficial"
              target="_blank"
              rel="noopener noreferrer"
              class="cursor-pointer"
            >
              <i
                class="fa-brands fa-instagram fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
              ></i>
            </a>
            <a
              href="https://www.tiktok.com/@kedasbeautypusat"
              target="_blank"
              rel="noopener noreferrer"
              class="cursor-pointer"
            >
              <i
                class="fa-brands fa-tiktok fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
              ></i>
              <!-- <img :src="tiktokIcon" /> -->
            </a>
            <a
              href="https://facebook.com/kedasbeuty.co.id"
              target="_blank"
              rel="noopener noreferrer"
              class="cursor-pointer"
            >
              <i
                class="fa-brands fa-facebook fa-lg text-[#6867AC] hover:scale-150 transition ease-in-out"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //   search: null,
      local: "http://127.0.0.1:8000",
      prod: process.env.VUE_APP_KB_API_URL,
      search_name: null,
      active: true,
      select: {
        countries: [],
        states: [],
        cities: [],
        province: [],
        regency: [],
      },
      selected: {
        country: null,
        state: null,
        district: null,
        name: null,
      },
      provinsi: null,
      loading: true,
      member: [],
      cities: [],
      mem_on_curr_prov: [],
      foreign: [],
      filtered: false,
      found: false,
      blueAvatarIcon: require("@/assets/empty-member-head-avatar-blue.svg"),
      purpleAvatarIcon: require("@/assets/empty-member-head-avatar-purple.svg"),
    };
  },
  methods: {
    async getMembers() {
      let complete = false;
      let vm = this;
      if (complete === false) {
        await axios
          .get(`${this.prod}/api/members/list`)
          .then((response) => {
            vm.member = response.data;

            complete = true;
            vm.loading = false;
          })
          .catch((err) => {
            console.log(err);
            complete = false;
          });
      }
      if (complete === true) this.active = false;
    },

    async getCountry() {
      let response = await axios.get(`${this.prod}/api/countries`);
      this.select.countries = response.data;
    },

    async getStates() {
      let response = await axios.get(
        `${this.prod}/api/countries/${this.selected.country}/states`
      );
      this.select.states = response.data;
    },

    async getCities() {
      let response = await axios.get(
        `${this.prod}/api/countries/${this.selected.country}/states/${this.selected.state}/cities`
      );
      this.select.cities = response.data;
    },

    async search() {
      this.loading = true;
      let data = this.selected;
      let complete = false;
      // console.log(data)
      await axios
        .post(`${this.prod}/api/members/list/search`, data)
        .then((response) => {
          this.member = response.data;
          // console.log(response.data);
          this.loading = false;
          complete = true;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });

      if (complete === true) this.active = false;
    },

    goToResellerLink(website_id) {
      window.open(
        `https://kedasbeautymember.com/v2/member?website_id=${website_id}`,
        "_blank"
      );
    },

    handlePagination(val) {
      this.active = true;
      this.member = [];
      if (
        !this.selected.country &&
        !this.selected.state &&
        !this.selected.district &&
        !this.selected.name
      ) {
        console.log("all");
        this.paginateMember(val).then(() => {
          this.active = false;
        });
      } else {
        // console.log("filtered");
        this.paginateFilteredMember(val).then(() => {
          this.active = false;
        });
      }
    },

    async paginateMember(page) {
      let response = await axios.get(
        `${this.prod}/api/members/list?page=${page}`
      );
      // console.log(response.data);
      this.member = response.data;
    },

    async paginateFilteredMember(page) {
      let data = this.selected;
      let response = await axios.post(
        `${this.prod}/api/members/list/search?page=${page}`,
        data
      );
      // console.log(response.data);
      this.member = response.data;
    },

    resetSearch() {
      this.$refs.filterForm.reset();
      this.getMembers();
      // console.log("Search Form Reseted");
    },
    showAlert() {
      if (this.search !== null) {
        this.found = !this.found;
      } else {
        this.found = false;
      }
    },
  },
  created() {
    this.getMembers();
    this.getCountry();
  },
};
</script>
